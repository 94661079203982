<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{module_name}}</h3> 
                        <p>You can add or update {{module_name}} here. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="col-6 lg:col-6 sm:col-12 mb-2"  style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Name</label>
                                    <InputText id="name" placeholder="Name" v-model="form.name"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="name" /> 
                                </div>
                                <div class="col-3 lg:col-3 sm:col-6 mb-2"  style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Company</label>
                                    <Dropdown CompanyLoading :filter="true" :showClear="true"  v-model="form.company_id" :options="companies" optionLabel="name" optionValue="id"  :placeholder="CompanyLoading ? 'Loading...' : 'Select Company'" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="company_id" />
                                </div>  
                                <div class="col-3 lg:col-3 sm:col-6 mb-2"  style="float:left"> 
                                    <label>Status</label>
                                    <Dropdown v-model="form.status" :options="RecordStatuses" optionLabel="name" optionValue="id" placeholder="Select Status" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="status" />
                                </div>   
                                <div class="col-3 lg:col-3 sm:col-6 mb-0" style="float:left"> 
                                    <label> Start Time</label>
                                    <Calendar :dateFormat="calendarFormat()" :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"  @date-select="startDateTimeSelected($event)" :showButtonBar="true" id="start_time" v-model="form.start_time" :showTime="true" :stepMinute="5" :showSeconds="false" class="full-width"/> 
                                    <HasError class="p-error" :form="form" field="start_time" /> 
                                </div> 
                                <div class="col-3 lg:col-3 sm:col-6 mb-0" style="float:left"> 
                                    <label>End Time <small>(Optional)</small></label>
                                    <Calendar :dateFormat="calendarFormat()" :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"  :minDate="minDateTime" :showButtonBar="true" :disabled="form.start_time == ''? true:false" id="end_time" v-model="form.end_time" :stepMinute="5" :showTime="true" :showSeconds="false" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="end_time" /> 
                                </div> 
                                <div class="col-3 lg:col-3 sm:col-6 mb-0" style="float:left"> 
                                    <label>Minimum Order Price </label>
                                    <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency" locale="en-US" :showButtons="true"  placeholder="Min Price" v-model="form.min_order_price" :min="0"  class="full-width"/>  
                                    <HasError class="p-error" :form="form" field="min_order_price" /> 
                                </div> 
                                <div class="col-3 lg:col-3 sm:col-6 mb-0" style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Vehicle Types</label>
                                    <MultiSelect VehicleTypesLoading v-model="form.vehicleTypes"  :filter="true" :showClear="true" :options="vehicle_types" optionLabel="name" optionValue="id" :placeholder="VehicleTypesLoading ? 'Loading...' : 'Select Vehicle Type'"  class="full-width"/>
                                    <HasError class="p-error" :form="form" field="vehicleTypes" />
                                </div>   
                                <div class="col-3 lg:col-3 sm:col-6 mb-0" style="float:left"> 
                                    <label>Price Affect </label>
                                    <Dropdown v-model="form.price_affect" :options="PriceAffectTypes" optionLabel="name" optionValue="id" placeholder="Select" class="full-width"/> 
                                    <HasError class="p-error" :form="form" field="price_affect" /> 
                                </div>  
                                <div class="col-9 lg:col-9 sm:col-9 mb-0" style="float:left"></div> 
                                 
                                <div  class="col-12 lg:col-12 mb-0 pl-0 pr-0">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-0" style="  float:left ">
                                        <div style="width:100%">
                                            <br/><h3>Fixed Routes</h3>
                                            <Divider/>
                                        </div> 
                                    </div>
                                    <div class="col-12 lg:col-12 sm:col-12 mb-0 pl-0 pr-0" style="  float:left ">
                                        <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                            <div> 
                                                <label>Journey Origin </label>
                                                <input type="text" ref="origin"  id="travel_origin" placeholder="Search Place" v-model="travel_origin"  class="full-width p-inputtext p-component  " />
                                            </div>
                                        </div> 
                                        <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                            <div> 
                                                <label>Journey Destination</label>
                                                <input type="text" ref="destination" id="travel_destination" placeholder="Search Place" v-model="travel_destination"  class="full-width p-inputtext p-component"/>
                                            </div>
                                        </div> 
                                        <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float:left">  
                                            <Button @click="addNewRoute()" type="button" :label="locationBtnLabel" icon="pi pi-plus" class="p-button-raised p-button-lg p-button-success mr-2 mt-3"/>
                                            <Button v-if="cancelUpdateLocationBtn" @click="cancelUpdateLocation()" type="button" :label="'Cancel'" icon="pi pi-times" class="p-button-raised p-button-lg p-button-danger mr-2 mt-3"/>
                                        </div>   
                                    </div>
                                    <div class="col-12 lg:col-12 sm:col-12 mb-0 " style="  float:left; display:flex ">
                                        <div class="col-4 lg:col-4 sm:col-12 mb-2" style="float:left" v-for="(route,key) in form.travel_routes" :key="key" >  
                                            <Panel  :header="'Route-'+(key+1)" :toggleable="true"  style="width:100%; height:100%"> 
                                                <template #icons>
                                                    <button :recIndex="key" class="p-panel-header-icon p-link mr-2" @click="openLocationOptions">
                                                        <span class="pi pi-cog"></span>
                                                    </button>
                                                    <Menu id="locationOptions"  ref="locationOptions" :model="locationOptions" :popup="true" />
                                                </template>
                                                <div> 
                                                    <h4>Origin</h4>
                                                    <p class="p-0 m-0"><strong>ZipCode# </strong>{{route['origin']['location'].zipcode}}</p>
                                                    <p class="p-0 m-0"><strong>Place ID# </strong>{{route['origin'].place_id}}</p>
                                                    <p class="p-0 m-0"><strong>Address# </strong>{{route['origin'].address}}</p>
                                                </div> 
                                                <div> 
                                                    <h4 class="mt-3">Destination</h4> 
                                                    <p class="p-0 m-0"><strong>ZipCode# </strong>{{route['destination']['location'].zipcode}}</p>
                                                    <p class="p-0 m-0"><strong>Place ID# </strong>{{route['destination'].place_id}}</p>
                                                    <p class="p-0 m-0"><strong>Address# </strong>{{route['destination'].address}}</p>
                                                </div> 
                                            </Panel>
                                        </div>
                                    </div>
                                </div> 
                                <Divider/>
                                <div class="col-6 lg:col-6 sm:col-12 mb-0 " style="float:left; clear:both"> 
                                    <div class="col-12 lg:col-12 sm:col-12 mb-0 pl-0 pr-0" style="  float:left ">
                                        <div style="width:100%">
                                            <h3>First Journey</h3>
                                            <Divider/>
                                        </div> 
                                    </div>
                                    <div class="col-12 lg:col-12 sm:col-12 mb-0 pl-0 pr-0" style="float:left; ">  
                                        <label>Change order price by </label>
                                        <Dropdown :filter="true" :showClear="true"  v-model="form.first_price_pattern" @change="changeFirstPriceRulingPattern($event)" :options="PriceRulingPattern" optionLabel="name" optionValue="id"  :placeholder="'Select Price Ruling Pattern'" class="full-width"/>
                                        <HasError class="p-error" :form="form" field="first_price_pattern" />  
                                    </div>
                                    <div v-if="!showFirstDayWisePricing" class="col-12 lg:col-12 sm:col-12 mb-0 pl-0 pr-0" style="float:left; ">
                                        <div class="col-5 lg:col-5 sm:col-12 mb-0 pl-0 pr-0" style="float:left"> 
                                            <label>Change By Percentage</label> 
                                            <InputNumber :showButtons="true" :step="0.5" :min="0" :max="100"  id="start_price_percentage" placeholder="Price Percentage" v-model="form.start_price_percentage"  class="full-width"/>
                                            <HasError class="p-error" :form="form" field="start_price_percentage" />  
                                        </div>
                                        <div class="col-2 lg:col-2 sm:col-12 mb-0 pl-0 pr-0 text-center"  style="float:left; align-items: center;"> 
                                            <strong >OR</strong> 
                                        </div>
                                        <div class="col-5 lg:col-5 sm:col-12 mb-0 pl-0 pr-0" style="float:left"> 
                                            <label>Change By Fixed Price</label>
                                            <InputNumber mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency" locale="en-US" :showButtons="true" :step="0.5" :min="0" id="start_fixed_price" placeholder="Fixed Price" v-model="form.start_fixed_price"  class="full-width"/>
                                            <HasError class="p-error" :form="form" field="start_fixed_price" /> 
                                        </div> 
                                    </div>
                                    <div v-else>
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float:left;display: flex; align-items: center;">
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" > 
                                                <strong >Monday</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Price Percentage</label>
                                                <InputNumber :showButtons="true" :step="0.5" :min="0" :max="100"  id="monday_first_price_percentage" placeholder="Price Percentage" v-model="form.monday_first_price_percentage"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="monday_first_price_percentage" /> 
                                            </div>
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >OR</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Fixed Price</label>
                                                <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency" locale="en-US"   :showButtons="true" :step="0.5" :min="0" id="monday_first_fixed_price" placeholder="Fixed Price" v-model="form.monday_first_fixed_price"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="monday_first_fixed_price" /> 
                                            </div>
                                        </div> 
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float:left;display: flex; align-items: center;">
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" > 
                                                <strong >Tuesday</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Price Percentage</label>
                                                <InputNumber :showButtons="true" :step="0.5" :min="0" :max="100"  id="tuesday_first_price_percentage" placeholder="Price Percentage" v-model="form.tuesday_first_price_percentage"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="tuesday_first_price_percentage" /> 
                                            </div>
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >OR</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Fixed Price</label>
                                                <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency" locale="en-US"   :showButtons="true" :step="0.5" :min="0" id="tuesday_first_fixed_price" placeholder="Fixed Price" v-model="form.tuesday_first_fixed_price"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="tuesday_first_fixed_price" /> 
                                            </div>
                                        </div> 
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float:left;display: flex; align-items: center;">
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" > 
                                                <strong >Wednesday</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Price Percentage</label>
                                                <InputNumber :showButtons="true" :step="0.5" :min="0" :max="100"  id="wednesday_first_price_percentage" placeholder="Price Percentage" v-model="form.wednesday_first_price_percentage"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="wednesday_first_price_percentage" /> 
                                            </div>
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >OR</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Fixed Price</label>
                                                <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency" locale="en-US"   :showButtons="true" :step="0.5" :min="0" id="wednesday_first_fixed_price" placeholder="Fixed Price" v-model="form.wednesday_first_fixed_price"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="wednesday_first_fixed_price" /> 
                                            </div>
                                        </div> 
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float:left;display: flex; align-items: center;">
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >Thursday</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Price Percentage</label>
                                                <InputNumber :showButtons="true" :step="0.5" :min="0" :max="100"  id="thursday_first_price_percentage" placeholder="Price Percentage" v-model="form.thursday_first_price_percentage"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="thursday_first_price_percentage" /> 
                                            </div>
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >OR</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Fixed Price</label>
                                                <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency" locale="en-US"   :showButtons="true" :step="0.5" :min="0" id="thursday_first_fixed_price" placeholder="Fixed Price" v-model="form.thursday_first_fixed_price"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="thursday_first_fixed_price" /> 
                                            </div>
                                        </div> 
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float:left;display: flex; align-items: center;">
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >Friday</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Price Percentage</label>
                                                <InputNumber :showButtons="true" :step="0.5" :min="0" :max="100"  id="friday_first_price_percentage" placeholder="Price Percentage" v-model="form.friday_first_price_percentage"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="friday_first_price_percentage" /> 
                                            </div>
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >OR</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Fixed Price</label>
                                                <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency" locale="en-US"   :showButtons="true" :step="0.5" :min="0" id="friday_first_fixed_price" placeholder="Fixed Price" v-model="form.friday_first_fixed_price"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="friday_first_fixed_price" /> 
                                            </div>
                                        </div> 
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float:left;display: flex; align-items: center;">
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >Saturday</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Price Percentage</label>
                                                <InputNumber :showButtons="true" :step="0.5" :min="0" :max="100"  id="saturday_first_price_percentage" placeholder="Price Percentage" v-model="form.saturday_first_price_percentage"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="saturday_first_price_percentage" /> 
                                            </div>
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >OR</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Fixed Price</label>
                                                <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency" locale="en-US"   :showButtons="true" :step="0.5" :min="0" id="saturday_first_fixed_price" placeholder="Fixed Price" v-model="form.saturday_first_fixed_price"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="saturday_first_fixed_price" /> 
                                            </div>
                                        </div> 
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float:left;display: flex; align-items: center;">
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >Sunday</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Price Percentage</label>
                                                <InputNumber :showButtons="true" :step="0.5" :min="0" :max="100"  id="sunday_first_price_percentage" placeholder="Price Percentage" v-model="form.sunday_first_price_percentage"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="sunday_first_price_percentage" /> 
                                            </div>
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >OR</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Fixed Price</label>
                                                <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency" locale="en-US"   :showButtons="true" :step="0.5" :min="0" id="sunday_first_fixed_price" placeholder="Fixed Price" v-model="form.sunday_first_fixed_price"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="sunday_first_fixed_price" /> 
                                            </div>
                                        </div> 
                                    </div>
                                </div>

                                <div class="col-6 lg:col-6 sm:col-12 mb-0 " style="float:left"> 
                                    <div class="col-12 lg:col-12 sm:col-12 mb-0 pl-0 pr-0" style="  float:left ">
                                        <div style="width:100%">
                                            <h3>Return Journey</h3>
                                            <Divider/>
                                        </div> 
                                    </div>
                                    <div class="col-12 lg:col-12 sm:col-12 mb-0 pl-0 pr-0" style="float:left; ">  
                                        <label>Change in order price by </label>
                                        <Dropdown :filter="true" :showClear="true"  v-model="form.return_price_pattern" @change="changeReturnPriceRulingPattern($event)" :options="PriceRulingPattern" optionLabel="name" optionValue="id"  :placeholder="'Select Price Ruling Pattern'" class="full-width"/>
                                        <HasError class="p-error" :form="form" field="return_price_pattern" />  
                                    </div>
                                    <div v-if="!showReturnDayWisePricing" class="col-12 lg:col-12 sm:col-12 mb-0 pl-0 pr-0" style="float:left; ">
                                        <div class="col-5 lg:col-5 sm:col-12 mb-0 pl-0 pr-0" style="float:left"> 
                                            <label>Change By Percentage</label> 
                                            <InputNumber  :showButtons="true" :step="0.5" :min="0" :max="100"  id="return_price_percentage" placeholder="Price Percentage" v-model="form.return_price_percentage"  class="full-width"/>
                                            <HasError class="p-error" :form="form" field="return_price_percentage" />  
                                        </div>
                                        <div class="col-2 lg:col-2 sm:col-12 mb-0 pl-0 pr-0 text-center"  style="float:left; align-items: center;"> 
                                            <strong >OR</strong> 
                                        </div>
                                        <div class="col-5 lg:col-5 sm:col-12 mb-0 pl-0 pr-0" style="float:left"> 
                                            <label>Change By Fixed Price</label>
                                            <InputNumber :showButtons="true" :step="0.5" :min="0" id="return_fixed_price" placeholder="Fixed Price" v-model="form.return_fixed_price"  class="full-width"/>
                                            <HasError class="p-error" :form="form" field="return_fixed_price" /> 
                                        </div> 
                                    </div>
                                    <div v-else>
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float:left;display: flex; align-items: center;">
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" > 
                                                <strong >Monday</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Price Percentage</label>
                                                <InputNumber :showButtons="true" :step="0.5" :min="0" :max="100"  id="monday_return_price_percentage" placeholder="Price Percentage" v-model="form.monday_return_price_percentage"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="monday_return_price_percentage" /> 
                                            </div>
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >OR</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Fixed Price</label>
                                                <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency" locale="en-US"   :showButtons="true" :step="0.5" :min="0" id="monday_return_fixed_price" placeholder="Fixed Price" v-model="form.monday_return_fixed_price"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="monday_return_fixed_price" /> 
                                            </div>
                                        </div> 
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float:left;display: flex; align-items: center;">
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" > 
                                                <strong >Tuesday</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Price Percentage</label>
                                                <InputNumber :showButtons="true" :step="0.5" :min="0" :max="100"  id="tuesday_return_price_percentage" placeholder="Price Percentage" v-model="form.tuesday_return_price_percentage"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="tuesday_return_price_percentage" /> 
                                            </div>
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >OR</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Fixed Price</label>
                                                <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency" locale="en-US"   :showButtons="true" :step="0.5" :min="0" id="tuesday_return_fixed_price" placeholder="Fixed Price" v-model="form.tuesday_return_fixed_price"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="tuesday_return_fixed_price" /> 
                                            </div>
                                        </div> 
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float:left;display: flex; align-items: center;">
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" > 
                                                <strong >Wednesday</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Price Percentage</label>
                                                <InputNumber :showButtons="true" :step="0.5" :min="0" :max="100"  id="wednesday_return_price_percentage" placeholder="Price Percentage" v-model="form.wednesday_return_price_percentage"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="wednesday_return_price_percentage" /> 
                                            </div>
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >OR</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Fixed Price</label>
                                                <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency" locale="en-US"   :showButtons="true" :step="0.5" :min="0" id="wednesday_return_fixed_price" placeholder="Fixed Price" v-model="form.wednesday_return_fixed_price"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="wednesday_return_fixed_price" /> 
                                            </div>
                                        </div> 
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float:left;display: flex; align-items: center;">
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >Thursday</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Price Percentage</label>
                                                <InputNumber :showButtons="true" :step="0.5" :min="0" :max="100"  id="thursday_return_price_percentage" placeholder="Price Percentage" v-model="form.thursday_return_price_percentage"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="thursday_return_price_percentage" /> 
                                            </div>
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >OR</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Fixed Price</label>
                                                <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency" locale="en-US"   :showButtons="true" :step="0.5" :min="0" id="thursday_return_fixed_price" placeholder="Fixed Price" v-model="form.thursday_return_fixed_price"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="thursday_return_fixed_price" /> 
                                            </div>
                                        </div> 
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float:left;display: flex; align-items: center;">
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >Friday</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Price Percentage</label>
                                                <InputNumber :showButtons="true" :step="0.5" :min="0" :max="100"  id="friday_return_price_percentage" placeholder="Price Percentage" v-model="form.friday_return_price_percentage"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="friday_return_price_percentage" /> 
                                            </div>
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >OR</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Fixed Price</label>
                                                <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency" locale="en-US"   :showButtons="true" :step="0.5" :min="0" id="friday_return_fixed_price" placeholder="Fixed Price" v-model="form.friday_return_fixed_price"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="friday_return_fixed_price" /> 
                                            </div>
                                        </div> 
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float:left;display: flex; align-items: center;">
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >Saturday</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Price Percentage</label>
                                                <InputNumber :showButtons="true" :step="0.5" :min="0" :max="100"  id="saturday_return_price_percentage" placeholder="Price Percentage" v-model="form.saturday_return_price_percentage"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="saturday_return_price_percentage" /> 
                                            </div>
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >OR</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Fixed Price</label>
                                                <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency" locale="en-US"   :showButtons="true" :step="0.5" :min="0" id="saturday_return_fixed_price" placeholder="Fixed Price" v-model="form.saturday_return_fixed_price"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="saturday_return_fixed_price" /> 
                                            </div>
                                        </div> 
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float:left;display: flex; align-items: center;">
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >Sunday</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Price Percentage</label>
                                                <InputNumber :showButtons="true" :step="0.5" :min="0" :max="100"  id="sunday_return_price_percentage" placeholder="Price Percentage" v-model="form.sunday_return_price_percentage"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="sunday_return_price_percentage" /> 
                                            </div>
                                            <div class="col-2 lg:col-2 sm:col-12 mb-0 text-center" style="float:left" > 
                                                <strong >OR</strong> 
                                            </div>
                                            <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float:left"> 
                                                <label>Fixed Price</label>
                                                <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency" locale="en-US"   :showButtons="true" :step="0.5" :min="0" id="sunday_return_fixed_price" placeholder="Fixed Price" v-model="form.sunday_return_fixed_price"  class="full-width"/>
                                                <HasError class="p-error" :form="form" field="sunday_return_fixed_price" /> 
                                            </div>
                                        </div> 
                                    </div>
                                </div>

                                <Divider />
                                
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left"> 
                                    <label>Description</label>
                                    <Textarea placeholder="Description" v-model="form.description" rows="5" cols="30" class="full-width" />
                                    <HasError class="p-error" :form="form" field="description" />
                                </div>   
                            </div>  
                        </div>  
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                                <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/>
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
    </BlockUI>
    
</template>
<script> 
// import MapAutoComplete from '../../components/MapAutoComplete.vue'; 
export default {
    data() {
			return {  
                form: new this.Form({ 
                    id: '', 
                    company_id: 0, 
                    name: '',   
                    start_time: 0,   
                    end_time: 0,   
                    min_order_price: 0,  
                    price_affect: '',  
                    travel_routes:[], 
                    first_price_pattern:0,     
                    return_price_pattern:0,
                    start_fixed_price:0,
                    start_price_percentage:0, 
                    return_fixed_price:0,
                    return_price_percentage:0,

                    monday_first_price_percentage:0,
                    monday_first_fixed_price:0,
                    tuesday_first_price_percentage:0,
                    tuesday_first_fixed_price:0,
                    wednesday_first_price_percentage:0,
                    wednesday_first_fixed_price:0,
                    thursday_first_price_percentage:0,
                    thursday_first_fixed_price:0,
                    friday_first_price_percentage:0,
                    friday_first_fixed_price:0,
                    saturday_first_price_percentage:0,
                    saturday_first_fixed_price:0,
                    sunday_first_price_percentage:0,
                    sunday_first_fixed_price:0,

                    monday_return_price_percentage:0,
                    monday_return_fixed_price:0,
                    tuesday_return_price_percentage:0,
                    tuesday_return_fixed_price:0,
                    wednesday_return_price_percentage:0,
                    wednesday_return_fixed_price:0,
                    thursday_return_price_percentage:0,
                    thursday_return_fixed_price:0,
                    friday_return_price_percentage:0,
                    friday_return_fixed_price:0,
                    saturday_return_price_percentage:0,
                    saturday_return_fixed_price:0,
                    sunday_return_price_percentage:0,
                    sunday_return_fixed_price:0,

                    vehicleTypes:[],

                    status:1, 
                    description: '',    
                }),   
                vehicle_types:[],
                VehicleTypesLoading:false,
                minDateTime:'', 
                cancelUpdateLocationBtn:'',
                travel_origin_place_obj:'',
                travel_destination_place_obj:'',
                locationBtnLabel:'Add New Route',
                loadMapAutoComplete:true,
                CompanyLoading:false,
                taxRatesLoading:false,
                ProductCategoriesLoading:false,
                module_name:'Pricing Rule',  
                isSaving:false,  
                saveAndClose:false, 
                recId:0,
                loadingComponent:true,
                formVisible:false, 
                companies:[], 
                taxRates:[],
                ProductCategories:[],
                showFirstDayWisePricing:false,
                showReturnDayWisePricing:false, 
                PriceRulingPattern:[{id:0,'name' : 'Same for all days'}, {id:1,'name':'Day wise different'}],
                PriceAffectTypes:[{id:'increase','name' : 'Increase'}, {id:'decrease','name':'Decrease'}], 
                travel_destination:'',
                travel_origin:'', 
                locationOptions:[],
                moduleId:62,
                selectedLocationIndex:'',
			}
		},   
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
        },
		created() {   
            
            // if(this.checkPermission(this.moduleId,'update')){
            //     this.locationOptions.push({
			// 			label: 'Update',
			// 			icon: 'pi pi-refresh',
            //             command: () => {
            //                 this.getClickedRecord('update');
            //             }
			// 		});
            // }
            if(this.checkPermission(this.moduleId,'delete')){
                this.locationOptions.push({
						label: 'Delete',
						icon: 'pi pi-times',
                        command: () => {
                            this.getClickedRecord('delete');
                        }
					});
            }  
		},
        computed: { 
        },
        components:{ 
        },
		mounted() {  
            this.initMapInputs(); 
            this.resetForm();
            this.formVisible = this.showForm; 
            this.recId = this.recordId;   
            this.getAllRequestData();   
		},
        
		methods: {  
            getClickedRecord(action){
                if(action == 'delete'){ 
                    this.deleteLocationRecord(); 
                } 
            },
            deleteLocationRecord(){ 
                if(this.selectedLocationIndex >= 0){
                    this.form.travel_routes.splice(this.selectedLocationIndex, 1);
                    this.selectedLocationIndex = '';    
                } 
            },
            openLocationOptions(event) { 
                this.$refs.locationOptions.toggle(event);
                this.selectedLocationIndex = event.currentTarget.getAttribute('recIndex');  
            },
            initMapInputs(){
                setTimeout( () => { 
                    for (let ref in this.$refs) {  
                        const autocomplete = new window.google.maps.places.Autocomplete(this.$refs[ref],{
                            bounds: new window.google.maps.LatLngBounds(new window.google.maps.LatLng(45.4215296,-75.6971931))
                        }); 
                        autocomplete.addListener("place_changed", () => { 
                            const place = autocomplete.getPlace();  
                            console.log(place);
                            if(ref == 'origin'){
                                this.travel_origin = place.name+' '+place.formatted_address;
                                this.travel_origin_place_obj = place; 
                            }
                            if(ref == 'destination'){
                                this.travel_destination = place.name+' '+place.formatted_address;
                                this.travel_destination_place_obj = place;
                            }
                        })
                    }
                },2000);
            },
            
            addNewRoute(){
                let add = true;
                if(Object.keys(this.form.travel_routes).length){
                    console.log(this.form.travel_routes);
                    this.form.travel_routes.forEach( (route) => {
                        if(route['origin'].place_id == this.travel_origin_place_obj.place_id && route['destination'].place_id == this.travel_destination_place_obj.place_id){
                            this.showMessage('Route has already been selected','error'); 
                            add = false; 
                        }
                    });
                }
                if(add){
                    let origin = {};
                    let destination = {};
                    if(this.travel_origin_place_obj.address_components){
                        this.travel_origin_place_obj.address_components.forEach( (component) => {
                            if(component.types){
                                component.types.forEach( (type) => {
                                    if(type == 'country'){
                                        origin.country = component.long_name;
                                    }
                                    else if(type == 'postal_code'){  
                                        origin.postal_code = component.long_name;
                                    }
                                    else if(type == 'administrative_area_level_1'){
                                        origin.state = component.long_name;
                                    }
                                    else if(type == 'administrative_area_level_2'){
                                        origin.city = component.long_name;
                                    }
                                    else if(type == 'administrative_area_level_3'){
                                        origin.area = component.long_name;
                                    }
                                    else if(type == 'locality'){
                                        origin.address3 = component.long_name;
                                    }
                                    else if(type == 'sublocality_level_1'){
                                        origin.address2 = component.long_name;
                                    }
                                    else if(type == 'route'){
                                        origin.address1 = component.long_name;
                                    }
                                })
                            }
                        })
                    }
                    if(this.travel_destination_place_obj.address_components){
                        this.travel_destination_place_obj.address_components.forEach( (component) => {
                            if(component.types){
                                component.types.forEach( (type) => {
                                    if(type == 'country'){
                                        destination.country = component.long_name;
                                    }
                                    else if(type == 'postal_code'){
                                        destination.postal_code = component.long_name;
                                    }
                                    else if(type == 'administrative_area_level_1'){
                                        destination.state = component.long_name;
                                    }
                                    else if(type == 'administrative_area_level_2'){
                                        destination.city = component.long_name;
                                    }
                                    else if(type == 'administrative_area_level_3'){
                                        destination.area = component.long_name;
                                    }
                                    else if(type == 'locality'){
                                        destination.address3 = component.long_name;
                                    }
                                    else if(type == 'sublocality_level_1'){
                                        destination.address2 = component.long_name;
                                    }
                                    else if(type == 'route'){
                                        destination.address1 = component.long_name;
                                    }
                                })
                            }
                        })
                    }
                    this.form.travel_routes.push({
                        'origin':{
                            place_id:this.travel_origin_place_obj.place_id,
                            address:this.travel_origin_place_obj.name+' '+this.travel_origin_place_obj.formatted_address,
                            location: origin ,
                        },
                        'destination':{
                            place_id:this.travel_destination_place_obj.place_id,
                            address:this.travel_destination_place_obj.name+' '+this.travel_destination_place_obj.formatted_address,
                            location: destination,
                        }
                    });
                    this.travel_origin_place_obj = this.travel_destination_place_obj = this.travel_origin = this.travel_destination = '';
                    
                }
            }, 
             
            changeFirstPriceRulingPattern($event){
                if($event.value){
                    this.showFirstDayWisePricing = true;
                }
                else{
                    this.showFirstDayWisePricing = false;
                }
            },
            changeReturnPriceRulingPattern($event){
                if($event.value){
                    this.showReturnDayWisePricing = true;
                }
                else{
                    this.showReturnDayWisePricing = false;
                }
            },
            startDateTimeSelected($event){
                this.form.end_time =  ''; 
                var $selectedDate = $event; 
                this.minDateTime = new Date($selectedDate); 
                
            },
            endDateTimeSelected($event){
                
            },
            getAllRequestData(){   
                this.$axios.all([this.updateRecord()])   
                .then(() => {  
                    this.getCompanies();   
                    this.getVehicleTypes();
                }) 
                .then(() => { 
                    this.blockComponent = false;     
                }).catch(() => {    
                //    this.getAllRequestData();
                })
                .finally(() => {  
                }); 
            },
            async getVehicleTypes(){ 
                var Vthis = this;
                Vthis.VehicleTypesLoading = true;
                await this.axios.post('getAllVehicleTypes')
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.vehicle_types = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    Vthis.VehicleTypesLoading = false;
                })  
            }, 
            async getCompanies(){ 
                var Vthis = this;
                Vthis.CompanyLoading = true;
                await this.axios.post('getAllCompanies')
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.companies = response.data;   
                        if(this.form.company_id==0){
                            this.form.company_id = response.data[0].id;
                        }
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    Vthis.CompanyLoading = false;
                })  
            },
             
             
            resetForm(){
                this.form.reset();
            },
            async save(){   
                var Vthis = this; 
                this.isSaving = true;
                console.log(this.form);
                await this.form
                .post(Vthis.$baseurl+'api/savePricingRule', Vthis.form)
                .then((response) => { 
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        console.log(response);
                        if(!this.recId){
                            //  this.getRecords();
                        }
                        // this.getRecords();
                        if(this.saveAndClose){   
                            setTimeout( () => {this.closeForm();},1000);
                            this.saveAndClose = false;
                            // this.closeForm();
                        }   
                        Vthis.showMessage('Saved successfully','success',); 
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false; 
                }) 
            }, 
            loadForm(){
                this.resetForm(); 
            },
            closeForm(){
                this.$emit('formClose',true); 
            },
             
            async updateRecord(){ 
                if(this.recId > 0){
                    var Vthis = this;
                    this.loading = true;
                    await this.axios.post('getAllPricingRules',{id:this.recId})
                    .then( (response) => {    
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){ 
                            if(response.data.pricing_rule){
                                response.data.pricing_rule[0].status = response.data.pricing_rule[0].status == 'Blocked' ? 0 : 1; 
                                Vthis.form.fill(response.data.pricing_rule[0]);  
                                var ids = response.data.pricing_rule[0].vehicleTypeIds.toString().split(',');
                                Vthis.form.vehicleTypes = ids.map(Number); 
                                if(response.data.pricing_rule[0].first_price_pattern){
                                    this.showFirstDayWisePricing = true;
                                }  
                                if(response.data.pricing_rule[0].return_price_pattern){
                                    this.showReturnDayWisePricing = true;
                                }  
                            }   
                            if(response.data.form_routes){
                                Vthis.form.travel_routes = []; 
                                Vthis.form.travel_routes = response.data.form_routes;
                            } 
                        }
                    })
                    .catch((error) => {    
                        this.printResponseResult(error);
                    })
                    .finally(() => { 
                        Vthis.loading = false;
                    })  
                }
            },   
            
            
		},
        watch: {
            
        }
}
</script>
 
